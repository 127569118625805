const SEND_TRANSACTION_START = 'tx/SEND_TRANSACTION_START'
const SEND_TRANSACTION_PENDING = 'tx/SEND_TRANSACTION_PENDING'
const SEND_TRANSACTION_SUCCEEDED = 'tx/SEND_TRANSACTION_SUCCEEDED'
const SEND_TRANSACTION_FAILED = 'tx/SEND_TRANSACTION_FAILED'
const OPEN_TX_PANEL = 'tx/OPEN_TX_PANEL'

export {
  SEND_TRANSACTION_START,
  SEND_TRANSACTION_SUCCEEDED,
  SEND_TRANSACTION_PENDING,
  SEND_TRANSACTION_FAILED,
  OPEN_TX_PANEL,
}
