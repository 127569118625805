export const SETUP_ETHEREUM_START = 'home/SETUP_ETHEREUM_START'
export const SETUP_ETHEREUM_SUCCEEDED = 'home/SETUP_ETHEREUM_SUCCEEDED'
export const SETUP_ETHEREUM_FAILED = 'home/SETUP_ETHEREUM_FAILED'
export const SELECT_REGISTRY_START = 'home/SELECT_REGISTRY_START'
export const SELECT_REGISTRY_SUCCEEDED = 'home/SELECT_REGISTRY_SUCCEEDED'
export const SELECT_REGISTRY_FAILED = 'home/SELECT_REGISTRY_FAILED'

export const SET_ABIS = 'home/SET_ABIS'
export const SET_REGISTRY_CONTRACT = 'home/SET_REGISTRY_CONTRACT'
export const SET_ALL_CONTRACTS = 'home/SET_ALL_CONTRACTS'
export const CHOOSE_TCR = 'home/CHOOSE_TCR'

export const UPDATE_BALANCES_START = 'home/UPDATE_BALANCES_START'
export const UPDATE_BALANCES_SUCCEEDED = 'home/UPDATE_BALANCES_SUCCEEDED'
export const UPDATE_BALANCES_FAILED = 'home/UPDATE_BALANCES_FAILED'
